/**
* Created by tudou on 2020/4/27 20:04.
*/
<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>

</style>
